var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-xl": "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", "align-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "material-card",
                { attrs: { color: "green" } },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("div", { staticClass: "title font-weight-light mb-2" }, [
                      _vm._v("Notifications"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "category" },
                      [
                        _vm._v(
                          "\n            Handcrafted by us with\n            "
                        ),
                        _c("v-icon", { attrs: { size: "17" } }, [
                          _vm._v("\n              mdi-heart\n            "),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md6: "", sm12: "" } },
                            [
                              _c(
                                "h2",
                                { staticClass: "title font-weight-light mb-3" },
                                [_vm._v("Notifications Style")]
                              ),
                              _c(
                                "material-notification",
                                {
                                  staticClass: "mb-3",
                                  attrs: { color: "info" },
                                },
                                [
                                  _vm._v(
                                    "\n                This is a plain notification\n              "
                                  ),
                                ]
                              ),
                              _c(
                                "material-notification",
                                {
                                  staticClass: "mb-3",
                                  attrs: { color: "info", dismissible: "" },
                                },
                                [
                                  _vm._v(
                                    "\n                This is a notification with close button.\n              "
                                  ),
                                ]
                              ),
                              _c(
                                "material-notification",
                                {
                                  staticClass: "mb-3",
                                  attrs: {
                                    color: "info",
                                    dismissible: "",
                                    icon: "mdi-bell-plus",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\n                This is a notification with close button and icon.\n              "
                                  ),
                                ]
                              ),
                              _c(
                                "material-notification",
                                {
                                  staticClass: "mb-3",
                                  attrs: {
                                    color: "info",
                                    dismissible: "",
                                    icon: "mdi-bell-plus",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\n                This is a notification with close button and icon and have many lines. You can see that the icon and the close button are always vertically aligned. This is a beautiful notification. So you don't have to worry about the style.\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { md6: "", sm12: "" } },
                            [
                              _c(
                                "h2",
                                { staticClass: "title font-weight-light" },
                                [_vm._v("Notifcation States")]
                              ),
                              _c(
                                "material-notification",
                                {
                                  staticClass: "mb-3",
                                  attrs: { color: "info", dismissible: "" },
                                },
                                [
                                  _c("strong", [_vm._v("INFO")]),
                                  _vm._v(
                                    ' - This is a regular notification made with `color="info"`\n              '
                                  ),
                                ]
                              ),
                              _c(
                                "material-notification",
                                {
                                  staticClass: "mb-3",
                                  attrs: { color: "success", dismissible: "" },
                                },
                                [
                                  _c("strong", [_vm._v("SUCCESS")]),
                                  _vm._v(
                                    ' - This is a regular notification made with `color="success"`\n              '
                                  ),
                                ]
                              ),
                              _c(
                                "material-notification",
                                {
                                  staticClass: "mb-3",
                                  attrs: { color: "warning", dismissible: "" },
                                },
                                [
                                  _c("strong", [_vm._v("WARNING")]),
                                  _vm._v(
                                    ' - This is a regular notification made with `color="warning"`\n              '
                                  ),
                                ]
                              ),
                              _c(
                                "material-notification",
                                {
                                  staticClass: "mb-3",
                                  attrs: { color: "error", dismissible: "" },
                                },
                                [
                                  _c("strong", [_vm._v("DANGER")]),
                                  _vm._v(
                                    ' - This is a regular notification made with `color="error"`\n              '
                                  ),
                                ]
                              ),
                              _c(
                                "material-notification",
                                {
                                  staticClass: "mb-3",
                                  attrs: { color: "purple", dismissible: "" },
                                },
                                [
                                  _c("strong", [_vm._v("PRIMARY")]),
                                  _vm._v(
                                    ' - This is a regular notification made with `color="purple"`\n              '
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "text-xs-center" }, [
                        _c(
                          "h2",
                          { staticClass: "title font-weight-light mb-2" },
                          [_vm._v("Notification Places")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "subheading font-weight-light grey--text",
                          },
                          [_vm._v("Click to view notifications")]
                        ),
                      ]),
                      _c(
                        "v-container",
                        { attrs: { "grid-list-lg": "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                "justify-center": "",
                                row: "",
                                wrap: "",
                              },
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { block: "", color: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.snack("top", "left")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Top Left\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { block: "", color: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.snack("top")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Top Center\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                "justify-center": "",
                                row: "",
                                wrap: "",
                              },
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { block: "", color: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.snack("top", "right")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Top Right\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { block: "", color: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.snack("bottom", "left")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Bottom Left\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                "justify-center": "",
                                row: "",
                                wrap: "",
                              },
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { block: "", color: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.snack("bottom")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Bottom Center\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { block: "", color: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.snack("bottom", "right")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Bottom Right\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-snackbar",
                        {
                          attrs: {
                            color: _vm.color,
                            bottom: _vm.bottom,
                            top: _vm.top,
                            left: _vm.left,
                            right: _vm.right,
                            dark: "",
                          },
                          model: {
                            value: _vm.snackbar,
                            callback: function ($$v) {
                              _vm.snackbar = $$v
                            },
                            expression: "snackbar",
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-3", attrs: { color: "white" } },
                            [
                              _vm._v(
                                "\n              mdi-bell-plus\n            "
                              ),
                            ]
                          ),
                          _c("div", [
                            _vm._v("Welcome to "),
                            _c("b", [_vm._v("Vue Material Dashboard")]),
                            _vm._v(
                              " - a beautiful freebie for every web developer."
                            ),
                          ]),
                          _c(
                            "v-icon",
                            {
                              attrs: { size: "16" },
                              on: {
                                click: function ($event) {
                                  _vm.snackbar = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              mdi-close-circle\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }